import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { DatesProvider } from '@mantine/dates'
import App from '@/app/App'
import { StoreProvider } from '@/app/providers/StoreProvider'
import { YandexMetrika } from '@/shared/ui/YandexMetrika/YandexMetrika'
import i18n from '@/shared/configs/i18n/i18n'
import 'dayjs/locale/ru'
import 'dayjs/locale/uz'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<BrowserRouter>
		<StoreProvider>
			<I18nextProvider i18n={i18n}>
				<DatesProvider settings={{ locale: 'ru' }}>
					<App />
				</DatesProvider>
			</I18nextProvider>
			<YandexMetrika />
		</StoreProvider>
	</BrowserRouter>,
)
