import { memo } from 'react'
import { Skeleton } from '@mantine/core'
import { EmptyList } from 'shared/ui/EmptyList/EmptyList'
import { useStyles } from './BalanceList.styles'
import { useGetBalanceList } from '../../api/balanceApi'
import { BalanceAutoRow } from '../BalanceAutoRow/BalanceAutoRow'

export const BalanceList = memo(() => {
	const { classes } = useStyles()

	const { data: balanceListData, isLoading } = useGetBalanceList()

	if (isLoading) {
		return <Skeleton w={'100%'} h={200} />
	}

	if (!balanceListData) return null

	return (
		<div className={classes.balanceList}>
			<BalanceAutoRow header />
			{balanceListData.result.list.length === 0 ? (
				<EmptyList />
			) : (
				balanceListData.result.list.map((item, index) => (
					<BalanceAutoRow key={item.uid} item={item} number={index} />
				))
			)}
		</div>
	)
})
BalanceList.displayName = 'BalanceList'
