import { FC, memo } from 'react'
import { Card, List, Radio, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Tariff } from '../../model/types/tariffSchema'

interface TariffCardProps {
	tariff: Tariff
	isYearsSubscr: boolean
}

export const TariffCard: FC<TariffCardProps> = memo((props) => {
	const { tariff, isYearsSubscr } = props
	const { t } = useTranslation('payment')

	const renderLabel = () => {
		const durationTitle = isYearsSubscr ? '(1 год)' : '(1 месяц)'
		const price = isYearsSubscr ? tariff.price_c12 * 12 : tariff.price
		return `${tariff.title} ${durationTitle} - ${price} ${t('руб.')}`
	}

	return (
		<>
			<Card shadow="md" withBorder h={'100%'} mb={50}>
				<Text fw={600}>{tariff.title}</Text>
				<List my={20}>
					{tariff.description_arg.map((description: string, index: number) => (
						<List.Item key={index}>{t(description)}</List.Item>
					))}
				</List>
			</Card>

			<Radio value={String(tariff.id)} label={renderLabel()} mb={25} />
		</>
	)
})

TariffCard.displayName = 'TariffCard'
