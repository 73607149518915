import { FC } from 'react'
import { Button, Grid, Modal, Text } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'
import { useStyles } from './ExitButton.styles'
import { ReactComponent as ExitSVG } from '@/shared/assets/icons/exit.svg'
import { useLogoutUser } from '@/entities/User/api/userAuthApi'

interface ExitButtonProps {
	navbar?: boolean
	page?: boolean
}

export const ExitButton: FC<ExitButtonProps> = (props) => {
	const { navbar, page } = props

	const { t } = useTranslation()

	const { classes } = useStyles()

	const [opened, { open, close }] = useDisclosure(false)

	const isDesktop = useMediaQuery('(min-width: 992px)')

	const siteUrl = process.env.REACT_APP_SITE_URL

	const [logoutUser, { isLoading }] = useLogoutUser()

	const handlerLogout = async () => {
		try {
			await logoutUser().unwrap()
			window.location.href = siteUrl as string
		} catch (e: any) {
			console.log(e.data.message)
		}
	}

	return (
		<>
			{page && isDesktop && (
				<div className={classes.exitButton}>
					<Button variant="subtle" compact className={classes.exitBtn} onClick={open}>
						<ExitSVG className={classes.icon} />
					</Button>
				</div>
			)}
			{navbar && !isDesktop && (
				<div className={classes.navbarBtn}>
					<Button p={0} variant="subtle" compact className={classes.exitBtn} onClick={open}>
						<ExitSVG className={classes.icon} /> {t('Выход')}
					</Button>
				</div>
			)}
			<Modal opened={opened} onClose={close} centered padding="lg">
				<Text align="center">{t('Вы действительно хотите покинуть личный кабинет?')}</Text>
				<Grid mt="xl">
					<Grid.Col xs={6}>
						<Button onClick={close} fullWidth variant="outline" color="dark.7">
							{t('Нет')}
						</Button>
					</Grid.Col>
					<Grid.Col xs={6}>
						<Button onClick={handlerLogout} color="dark" fullWidth loading={isLoading}>
							{t('Да')}
						</Button>
					</Grid.Col>
				</Grid>
			</Modal>
		</>
	)
}
