import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	balanceAutoRow: {
		display: 'flex',
		padding: '20px 0',

		[theme.fn.smallerThan('sm')]: {
			position: 'relative',
			flexWrap: 'wrap',
			paddingRight: 30,
		},
	},
	header: {
		color: theme.colors.gray[6],
		[theme.fn.smallerThan('sm')]: {
			display: 'none !important',
		},
	},
	number: {
		maxWidth: '5%',
		flex: '0 0 5%',
		[theme.fn.smallerThan('sm')]: {
			flex: '1 1 auto',
			maxWidth: 50,
			marginBottom: 20,
		},
	},
	type: {
		flex: '1 1 auto',
		maxWidth: '20%',
		[theme.fn.smallerThan('sm')]: {
			flex: '1 1 auto',
			maxWidth: 'none',
			marginBottom: 20,
		},
	},
	income: {
		color: theme.colors.green[6],
	},
	expense: {
		color: theme.colors.red[6],
	},
	sum: {
		flex: '1 1 auto',
		maxWidth: '20%',
		[theme.fn.smallerThan('sm')]: {
			flex: '1 1 100%',
			maxWidth: 'none',
			marginBottom: 20,
		},
	},
	date: {
		flex: '1 1 auto',
		maxWidth: '20%',
		[theme.fn.smallerThan('sm')]: {
			flex: '1 1 100%',
			maxWidth: 'none',
			marginBottom: 20,
		},
	},
	description: {
		flex: '1 1 auto',
		maxWidth: '20%',
		[theme.fn.smallerThan('sm')]: {
			flex: '1 1 auto',
			maxWidth: 'none',
		},
	},
	special: {
		flex: '0 0 5%',
		maxWidth: '5%',
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.fn.smallerThan('sm')]: {
			maxWidth: 30,
			flex: '1 1 30px',
			position: 'absolute',
			right: 0,
			bottom: 20,
		},
	},
}))
