import { ChangeEvent, FC, memo } from 'react'
import classNames from 'classnames'
import { Button, Popover, Text } from '@mantine/core'
import { IconCreditCard, IconTrash } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './CardDisplay.styles'
import { useDeleteBankCard } from '../../api/paymentMethodsApi'
import { useGlobalAlertModal } from '@/widgets/GlobalAlertModal'

interface CardDisplayProps {
	guid: string
	cardNumber: string
	selectedValue: string
	handleSetCardUid: (newVal: string) => void
}

export const CardDisplay: FC<CardDisplayProps> = memo((props) => {
	const { guid, cardNumber, selectedValue, handleSetCardUid } = props

	const { t } = useTranslation('payment')

	const { classes } = useStyles()

	const [deleteBankCard, { isLoading }] = useDeleteBankCard()
	const { openGlobalAlert } = useGlobalAlertModal()

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		handleSetCardUid(event.target.value)
	}

	const handleDelete = async () => {
		try {
			await deleteBankCard(guid).unwrap()
		} catch (e: any) {
			openGlobalAlert({
				type: 'error',
				title: t('Ошибка'),
				message: e.data.message ?? t('Неизвестная ошибка, пожалуйста обратитесь в тех.поддержку!'),
			})
		}
	}

	const isChecked = guid === selectedValue

	return (
		<div className={classes.cardDisplay}>
			<div className={classNames(classes.contentCard, { [classes.cheked]: isChecked })}>
				<input
					type="radio"
					value={guid ?? ''}
					name="bankCard"
					className={classes.input}
					checked={isChecked}
					onChange={handleInputChange}
				/>
				<span className={classes.text}>{cardNumber}</span>
				{guid !== '' && <IconCreditCard stroke={1.3} size={20} color={isChecked ? 'white' : 'black'} />}
				{isChecked && guid !== '' && (
					<div className={classes.buttonWrapper}>
						<Popover position="top">
							<Popover.Target>
								<Button compact color="dark" variant="light" px={5} py={0}>
									<IconTrash size={16} />
								</Button>
							</Popover.Target>
							<Popover.Dropdown p="0">
								<Button variant="filled" color="red" compact loading={isLoading} onClick={handleDelete}>
									<Text fz="xs" fw={400}>
										{t('Удалить карту')}
									</Text>
								</Button>
							</Popover.Dropdown>
						</Popover>
					</div>
				)}
			</div>
		</div>
	)
})

CardDisplay.displayName = 'CardDisplay'
