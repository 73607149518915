import { Flex, UnstyledButton } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { memo } from 'react'
import '/node_modules/flag-icons/css/flag-icons.min.css'

export const LangSwitcher = memo(() => {
	const { t, i18n } = useTranslation()

	const toggle = async () => {
		i18n.changeLanguage(i18n.language === 'ru' ? 'uz' : 'ru')
	}

	return (
		<UnstyledButton fz="15px" c="white" onClick={toggle}>
			<Flex gap="5px">
				{i18n.language === 'ru' ? <span className="fi fi-ru"></span> : <span className="fi fi-uz"></span>}
				{t('Язык')}
			</Flex>
		</UnstyledButton>
	)
})

LangSwitcher.displayName = 'LangSwitcher'
