import { FC, memo } from 'react'
import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useStyles } from './PaymentSubscriptionButton.styles'
import { useFastPaySubscr, usePayFromBalanceSubscr, usePaySubscr } from '../../api/updateSubscrApi'
import { useGlobalAlertModal } from '@/widgets/GlobalAlertModal'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'
import { paymentWithBalanceActions } from '@/features/paymentWithBalance'
import { getDurationSubscr, getIdSubscr } from '@/widgets/PaymentSubscription'

interface PaymentSubscriptionButtonProps {
	selectedTariffId?: number
	onCloseModal: () => void
	cardUid: string
	isPaymentWithBalance: boolean
	requiredPrice: number
	acceptOffer: boolean
	promoCode: string
	releaseUID?: string
}

export const PaymentSubscriptionButton: FC<PaymentSubscriptionButtonProps> = memo((props) => {
	const {
		selectedTariffId,
		onCloseModal,
		cardUid,
		isPaymentWithBalance,
		requiredPrice,
		acceptOffer,
		promoCode,
		releaseUID,
	} = props
	const { classes } = useStyles()

	const dispatch = useAppDispatch()

	const { t } = useTranslation('payment')

	const [paySubscr, { isLoading: isLoadingPay }] = usePaySubscr()
	const [fastPaySubscr, { isLoading: isLoadingFastPay }] = useFastPaySubscr()
	const [payFromBalance, { isLoading: isLoadingPayFromBalance }] = usePayFromBalanceSubscr()

	const idSubscr = useSelector(getIdSubscr)
	const durationSubscr = useSelector(getDurationSubscr)

	const { openGlobalAlert } = useGlobalAlertModal()

	const handlePaySubscr = async () => {
		//Метод оплаты с новой карты (перекидывает на Юмани)
		try {
			const response = await paySubscr({
				tarif: Number(idSubscr),
				alt: false,
				release_uid: releaseUID,
				promocode: promoCode ?? null,
				count: Number(durationSubscr),
			}).unwrap()

			if (response.link) {
				window.location.href = response.link
			}
		} catch (e: any) {
			openGlobalAlert({
				type: 'error',
				message: e.data.message ?? t('Неизвестная ошибка при переводе на оплату'),
				title: t('Ошибка'),
			})
		}
	}

	const handleFastPaySubscr = async () => {
		//Метод оплаты с привязаной карты
		if (!cardUid) return
		try {
			await fastPaySubscr({
				tarif: Number(idSubscr),
				card_uid: cardUid,
				release_uid: releaseUID,
				promocode: promoCode ?? null,
				count: Number(durationSubscr),
			}).unwrap()
			openGlobalAlert({
				type: 'success',
				message: t('Вы успешно оплатили подписку с карты'),
				title: t('Поздравляем'),
			})
			onCloseModal()
		} catch (e: any) {
			openGlobalAlert({
				type: 'error',
				message: e.data.message ?? t('Неизвестная ошибка оплаты с карты'),
				title: t('Ошибка'),
			})
		}
	}

	const handlePayFromBalance = async () => {
		//Метод оплаты с баланса
		//Поскольку он самый малозатратный по ресурсам на сервере, также используется для смены тарифа, если требуемая сумма равна 0
		try {
			await payFromBalance({
				tarif: Number(idSubscr),
				release_uid: releaseUID,
				promocode: promoCode ?? null,
				count: Number(durationSubscr),
			}).unwrap()

			if (promoCode) {
				openGlobalAlert({
					type: 'success',
					message: t('Вы успешно применили промокод'),
					title: t('Поздравляем'),
				})
			} else if (!requiredPrice) {
				openGlobalAlert({
					type: 'success',
					message: t('Вы успешно изменили тариф'),
					title: t('Поздравляем'),
				})
			} else {
				openGlobalAlert({
					type: 'success',
					message: t('Вы успешно оплатили подписку с баланса'),
					title: t('Поздравляем'),
				})
			}

			dispatch(paymentWithBalanceActions.changeBalancePaymentEnabled(false))
			onCloseModal()
		} catch (e: any) {
			openGlobalAlert({
				type: 'error',
				message: e.data.message ?? t('Неизвестная ошибка оплаты с баланса'),
				title: t('Ошибка'),
			})
		}
	}

	const handlePayWithPromocode = async () => {
		if (isPaymentWithBalance) {
			await handlePayFromBalance()
		} else if (cardUid) {
			await handleFastPaySubscr()
		} else {
			await handlePaySubscr()
		}
	}

	if (promoCode) {
		return (
			<Button fullWidth disabled={!acceptOffer} onClick={handlePayWithPromocode} loading={isLoadingPay}>
				Оплатить с промокодом
			</Button>
		)
	}

	if (!requiredPrice) {
		return (
			<Button fullWidth disabled={!acceptOffer} onClick={handlePayFromBalance} loading={isLoadingPay}>
				{t('Изменить тариф')}
			</Button>
		)
	}

	if (isPaymentWithBalance) {
		return (
			<Button fullWidth disabled={!acceptOffer} onClick={handlePayFromBalance} loading={isLoadingPayFromBalance}>
				{t('Оплатить с баланса')}
			</Button>
		)
	}

	if (!cardUid) {
		return (
			<Button fullWidth disabled={!acceptOffer} onClick={handlePaySubscr} loading={isLoadingPay}>
				{t('Оплатить с новой карты')}
			</Button>
		)
	}

	return (
		<Button fullWidth disabled={!acceptOffer} onClick={handleFastPaySubscr} loading={isLoadingFastPay}>
			{t('Оплатить с карты')}
		</Button>
	)
})

PaymentSubscriptionButton.displayName = 'PaymentSubscriptionButton'
