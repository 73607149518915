import { UserFromNotRussia } from '../model/types/paymentIsNotRussiaSchema'
import { rtkApi } from '@/shared/api/rtkApi'

const paymentIsNotRussiaApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		payIsNotRussia: build.mutation<any, UserFromNotRussia>({
			query: (body) => ({
				url: '/pay/order_not_russia',
				method: 'POST',
				body,
			}),
		}),
	}),
})

export const usePayIsNotRussia = paymentIsNotRussiaApi.usePayIsNotRussiaMutation
