import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { StateSchema } from '@/app/providers/StoreProvider/config/StateSchema'

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions,
) => {
	const state: StateSchema = api.getState() as StateSchema
	const payUrl = process.env.REACT_APP_PAY_URL

	const dynamicUrl = payUrl + '/api/v1'

	return fetchBaseQuery({
		baseUrl: dynamicUrl,
		prepareHeaders: (headers) => {
			const token = state.user.userToken

			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	})(args, api, extraOptions)
}

export const payApi = createApi({
	reducerPath: 'payApi',
	tagTypes: ['cards'],
	baseQuery: dynamicBaseQuery,
	endpoints: () => ({}),
})
