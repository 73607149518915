import { useDispatch, useSelector } from 'react-redux'
import { globalAlertModalActions } from '../model/slices/globalAlertModalSlice'
import { getAlertDetails } from '../model/selectors/getAlertDetails'
import { AlertDetailsType } from '../model/types/globalAlertModal'

export function useGlobalAlertModal() {
	const dispatch = useDispatch()
	const alertDetails = useSelector(getAlertDetails)

	const openGlobalAlert = ({ message, title, type, link }: AlertDetailsType) => {
		dispatch(globalAlertModalActions.reducerAlertDetails({ message, title, type, link }))
	}

	const closeGlobalAlert = () => {
		dispatch(
			globalAlertModalActions.reducerAlertDetails({
				message: '',
				title: '',
				type: alertDetails.type,
				link: undefined,
			}),
		)
	}

	return {
		openGlobalAlert,
		closeGlobalAlert,
	}
}
