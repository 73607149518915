import { memo, FC } from 'react'
import { Button } from '@mantine/core'
import type { ButtonProps } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'
import { paymentSubscriptionActions } from '@/widgets/PaymentSubscription'
interface SelectSubscriptionButtonProps extends ButtonProps {
	tarif_id?: number
}

export const SelectSubscriptionButton: FC<SelectSubscriptionButtonProps> = memo((props) => {
	const { tarif_id, ...otherProps } = props

	const dispatch = useAppDispatch()

	const { t } = useTranslation('payment')

	const handlerOpenModal = () => {
		dispatch(paymentSubscriptionActions.openModal())
	}

	return (
		<Button color={'teal'} compact onClick={handlerOpenModal} {...otherProps}>
			{tarif_id ? t('Изменить тариф') : t('Выбрать и оплатить')}
		</Button>
	)
})

SelectSubscriptionButton.displayName = 'SelectSubscriptionButton'
