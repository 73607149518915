import { FC, memo } from 'react'
import { ActionIcon, Popover, Text } from '@mantine/core'
import { IconInfoSquareFilled } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './SubscrEndTooltip.styles'

interface SubscrEndTooltipProps {
	dateEnd: string
}

export const SubscrEndTooltip: FC<SubscrEndTooltipProps> = memo((props) => {
	const { dateEnd } = props
	const { classes } = useStyles()
	const { t } = useTranslation('profile')

	return (
		<Popover shadow="md" width={250}>
			<Popover.Target>
				<ActionIcon variant="subtle" color="red" className={classes.icon}>
					<IconInfoSquareFilled />
				</ActionIcon>
			</Popover.Target>
			<Popover.Dropdown>
				<Text fz={'sm'} ta={'justify'}>
					{`${t(
						'Тариф был отменен, и автоматическое списание средств для его продления не будет проведено. Тариф будет активен до',
					)} ${dateEnd}, ${t('после чего его действие завершится.')}`}
				</Text>
			</Popover.Dropdown>
		</Popover>
	)
})

SubscrEndTooltip.displayName = 'SubscrEndTooltip'
