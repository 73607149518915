import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	userSidebarCard: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
	},
	ava: {
		width: 100,
		height: 100,
		borderRadius: '50%',
		marginBottom: theme.spacing.md,
	},
	name: {
		fontWeight: 600,
		fontSize: 16,
	},
	email: {
		fontSize: 14,
		color: theme.colors.gray[6],
	},
}))
