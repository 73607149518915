import {
	FastPaySubscrRequest,
	PayFromBalanceRequest,
	PaySubscrRequest,
	PaySubscrResponse,
} from '../model/types/updateSubscrSchema'
import { rtkApi } from '@/shared/api/rtkApi'

const updateSubscrApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		toggleEndSubscr: build.mutation<void, void>({
			query: () => ({
				url: '/v3/user/subscr/end-toggle',
				method: 'GET',
			}),
			invalidatesTags: [{ type: 'subscr', id: 'all' }],
		}),
		paySubscr: build.mutation<PaySubscrResponse, PaySubscrRequest>({
			query: (body) => ({
				url: '/v3/user/subscr/pay',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'subscr', id: 'all' }],
		}),
		fastPaySubscr: build.mutation<void, FastPaySubscrRequest>({
			query: (body) => ({
				url: '/v3/user/subscr/pay-fast',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'subscr', id: 'all' }],
		}),
		payFromBalanceSubscr: build.mutation<void, PayFromBalanceRequest>({
			query: (body) => ({
				url: '/v3/user/subscr/pay-balance',
				method: 'POST',
				body,
			}),
			invalidatesTags: [
				{ type: 'balance', id: 'all' },
				{ type: 'subscr', id: 'all' },
			],
		}),
	}),
})

export const useToggleEndSubscr = updateSubscrApi.useToggleEndSubscrMutation
export const usePaySubscr = updateSubscrApi.usePaySubscrMutation
export const useFastPaySubscr = updateSubscrApi.useFastPaySubscrMutation
export const usePayFromBalanceSubscr = updateSubscrApi.usePayFromBalanceSubscrMutation
