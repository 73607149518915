import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	alert: {
		animation: 'slideInFromRight 0.6s forwards',
		'@keyframes slideInFromRight': {
			'0%': {
				transform: 'translateX(60%)',
				opacity: 0,
			},
			'70%': {
				transform: 'translateX(-6%)',
				opacity: 0.8,
			},
			'100%': {
				transform: 'translateX(0)',
				opacity: 1,
			},
		},
	},
}))
