import { YMInitializer } from 'react-yandex-metrika'

export const YandexMetrika = () => {
	if (process.env.NODE_ENV === 'development') {
		console.log('DEV!')
		return null
	} else {
		console.log('PROD!')
		return (
			<YMInitializer
				accounts={[82816729]}
				version={'2'}
				options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
			/>
		)
	}
}
