import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PaymentWithBalanceStateSchema } from '../types/paymentWithBalanceSchema'
import { balanceApi } from '@/entities/Balance'

const initialState: PaymentWithBalanceStateSchema = {
	isBalancePaymentEnabled: false,
	balanceAmount: 0,
}

export const paymentWithBalanceSlice = createSlice({
	name: 'paymentWithBalance',
	initialState,
	reducers: {
		changeBalancePaymentEnabled: (state, { payload }: PayloadAction<boolean>) => {
			state.isBalancePaymentEnabled = payload
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(balanceApi.endpoints.getBalance.matchFulfilled, (state, { payload }) => {
			state.balanceAmount = payload.result.balance
		})
	},
})

export const { actions: paymentWithBalanceActions } = paymentWithBalanceSlice
export const { reducer: paymentWithBalanceReducer } = paymentWithBalanceSlice
