import { rtkApi } from 'shared/api/rtkApi'
import { BalanceResponse, BalanceListResponse } from '../model/types/balance'

export const balanceApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		getBalance: build.query<BalanceResponse, void>({
			query: () => ({
				url: '/balance/get',
			}),
			providesTags: () => [{ type: 'balance', id: 'all' }],
		}),
		getBalanceList: build.query<BalanceListResponse, void>({
			query: () => ({
				url: '/balance/list',
			}),
			providesTags: () => [{ type: 'balance', id: 'all' }],
		}),
	}),
})

export const useGetBalance = balanceApi.useGetBalanceQuery
export const useGetBalanceList = balanceApi.useGetBalanceListQuery
