import classNames from 'classnames'
import { FC } from 'react'
import { UserNameEmailCard } from 'entities/User'
import { useStyles } from './MenuNavbar.styles'
import { MenuList } from '@/widgets/Menu'
import { ExitButton } from '@/widgets/ExitButton'
import { useMainMenuItems, useSecondMenuItems } from '@/shared/configs/menuConfig/menuConfig'
import { LangSwitcher } from '@/widgets/LangSwitcher'

interface MenuNavbarProps {
	className?: string
	opened: boolean
}

export const MenuNavbar: FC<MenuNavbarProps> = (props) => {
	const { className, opened } = props

	const { classes } = useStyles()

	const mods = {
		[classes.open]: opened,
		[classes.close]: !opened,
	}

	const modsMenuList = {
		[classes.view]: opened,
		[classes.hide]: !opened,
	}

	const mainMenuItemsList = useMainMenuItems()
	const secondaryMenuItemsList = useSecondMenuItems()

	return (
		<div className={classNames(classes.menuNavbar, mods, [className])}>
			<UserNameEmailCard className={classNames(classes.menu, modsMenuList, [classes.nameWrapper])} />
			<MenuList
				dataMenuItems={mainMenuItemsList}
				className={classNames(classes.menu, modsMenuList, [classes.mainMenu])}
			/>
			<MenuList
				dataMenuItems={secondaryMenuItemsList}
				className={classNames(classes.menu, modsMenuList, [classes.secondaryMenu])}
			/>
			<div className={classNames('', modsMenuList)}>
				<ExitButton navbar={true} />
			</div>
			<div className={classNames(classes.lang, modsMenuList)}>
				<LangSwitcher />
			</div>
		</div>
	)
}
