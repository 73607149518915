import { ShowCostSubscrRequest, ShowCostSubscrResponse, SubcrDataResponse } from '../model/types/subscrSchema'
import { rtkApi } from '@/shared/api/rtkApi'

export const subscrApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		checkDemoSubscr: build.query<{ demo: boolean }, void>({
			query: () => ({
				url: '/user/has-demo-subscr',
				method: 'GET',
			}),
		}),
		getSubscrData: build.query<SubcrDataResponse, void>({
			query: () => ({
				url: '/v3/user/subscr/info',
			}),
			transformResponse(response: SubcrDataResponse) {
				if (response.description) {
					response.description_arg = response.description
						.split('\r\n')
						.map((item) => item.replace('•', '').trim())
				}
				return response
			},
			providesTags: () => [{ type: 'subscr', id: 'all' }],
		}),
		showPriceSubscr: build.mutation<ShowCostSubscrResponse, ShowCostSubscrRequest>({
			query: (body) => ({
				url: '/v3/user/subscr/sum',
				method: 'POST',
				body,
			}),
		}),
	}),
})

export const useLazyCheckDemoSubscr = subscrApi.useLazyCheckDemoSubscrQuery
export const useShowPriceSubscr = subscrApi.useShowPriceSubscrMutation
export const useGetSubscrData = subscrApi.useGetSubscrDataQuery
