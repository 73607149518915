import { FC, memo, useState } from 'react'
import { Stack } from '@mantine/core'
import { useSelector } from 'react-redux'
import { useStyles } from './PaymentSubscriptionScreen.styles'
import { getPromoError, getRequiredPrice, SubscriptionPriceInfo } from '@/entities/Subscr'
import { getIsBalancePaymentEnabled, PaymentWithBalanceCheckbox } from '@/features/paymentWithBalance'
import { getPaymentPromo, PaymentWithPromo } from '@/features/paymentWithPromo'
import { CardCarousel, getCardUid } from '@/features/paymentMethods'
import { OfferCheckbox } from '@/entities/Offer'
import { PaymentSubscriptionButton } from '@/features/updateSubscr'
import { useResponsive } from '@/shared/hooks/useResponsive/useResponsive'
import { PaymentMenu } from '@/shared/ui/PaymentMenu/PaymentMenu'
import { PaymentIsNotRussiaForm } from '@/features/paymentIsNotRussia'

interface PaymentSubscriptionScreenProps {
	onClose: () => void
	releaseUID?: string
}

export const PaymentSubscriptionScreen: FC<PaymentSubscriptionScreenProps> = memo((props) => {
	const { onClose, releaseUID } = props
	const { classes } = useStyles()

	const { xs } = useResponsive()

	const [acceptOffer, setAcceptOffer] = useState<boolean>(false)
	const cardUid = useSelector(getCardUid)
	const isPaymentWithBalance = useSelector(getIsBalancePaymentEnabled)
	const requiredPrice = useSelector(getRequiredPrice)
	const promoCode = useSelector(getPaymentPromo)
	const promoError = useSelector(getPromoError)
	const handleCloseModal = () => {
		onClose()
	}

	return (
		<PaymentMenu
			LeftBlock={
				<Stack spacing={30}>
					<SubscriptionPriceInfo promoCode={promoCode} />
					<PaymentWithBalanceCheckbox requiredPrice={requiredPrice} />
					<PaymentWithPromo promoError={promoError} />
					<CardCarousel
						sliderSize={xs ? '60%' : '33%'}
						disabledCarousel={isPaymentWithBalance || !requiredPrice}
					/>
					<OfferCheckbox accept={acceptOffer} setAccept={setAcceptOffer} />
					<PaymentSubscriptionButton
						acceptOffer={acceptOffer}
						requiredPrice={requiredPrice}
						cardUid={cardUid}
						isPaymentWithBalance={isPaymentWithBalance}
						onCloseModal={handleCloseModal}
						promoCode={promoError ? '' : promoCode}
						releaseUID={releaseUID}
					/>
				</Stack>
			}
			RightBlock={<PaymentIsNotRussiaForm />}
		/>
	)
})

PaymentSubscriptionScreen.displayName = 'PaymentSubscriptionScreen'
