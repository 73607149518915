import { NotificationsDetailedMessage, NotificationsMessagesResponse } from '../model/types/notificationsSchema'
import { rtkApi } from '@/shared/api/rtkApi'

const notificationsApi = rtkApi.injectEndpoints({
	endpoints: (builder) => ({
		getMessages: builder.query<NotificationsMessagesResponse, void>({
			query: () => ({
				url: '/messages',
				method: 'GET',
			}),
		}),
		markMessageAsRead: builder.mutation<NotificationsDetailedMessage, string>({
			query: (id) => ({
				url: `/messages/${id}`,
				method: 'GET',
			}),
		}),
	}),
})

export const useGetMessages = notificationsApi.useGetMessagesQuery
export const useMarkMessageAsRead = notificationsApi.useMarkMessageAsReadMutation
