import classNames from 'classnames'
import { FC, ButtonHTMLAttributes } from 'react'
import { ReactComponent as HamburgerSVG } from 'shared/assets/icons/hamburger-menu.svg'
import { ReactComponent as HamburgerOpenSVG } from 'shared/assets/icons/hamburger-menu-open.svg'
import { useStyles } from './HamburgerButton.styles'

interface HamburgerButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string
	opened: boolean
}

export const HamburgerButton: FC<HamburgerButtonProps> = (props) => {
	const { className, opened, ...outherProps } = props

	const { classes } = useStyles()

	return (
		<button type="button" className={classNames(classes.hamburgerButton, {}, [className])} {...outherProps}>
			{opened ? <HamburgerOpenSVG /> : <HamburgerSVG />}
		</button>
	)
}
