import { Global } from '@mantine/core'

export const GlobalStyle = () => {
	return (
		<Global
			styles={(theme) => ({
				'*': {
					boxSizing: 'border-box',
					margin: 0,
					padding: 0,
				},
				body: {
					fontFamily: theme.fontFamily,
					fontSize: 15,
				},
				button: {
					border: 'none',
					font: 'inherit',
					color: 'inherit',
					backgroundColor: 'transparent',
					cursor: 'pointer',
					outline: 'none',
					WebkitAppearance: 'none',
					MozAppearance: 'none',
					appearance: 'none',
				},
				a: {
					color: 'inherit',
					textDecoration: 'none',
				},
				'.app': {
					minHeight: '100vh',
				},
				'.content-page': {
					display: 'flex',
					backgroundColor: theme.colors.gray[0],
					[theme.fn.smallerThan('md')]: {
						flexDirection: 'column',
						minHeight: '100vh',
					},
				},
				'.page-wrapper': {
					flexGrow: 1,
					padding: 50,
					position: 'relative',
					[theme.fn.smallerThan('md')]: {
						padding: '115px 30px 30px 30px',
					},
					[theme.fn.smallerThan('xs')]: {
						padding: '115px 10px 10px 10px',
					},
				},
				'.main-button': {
					maxWidth: 200,
					[theme.fn.smallerThan('xs')]: {
						maxWidth: 'none',
					},
				},
			})}
		/>
	)
}
