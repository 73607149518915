import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	menuNavbar: {
		background: 'linear-gradient(177.15deg, #333333 -2.6%, #252525 100.5%)',
		borderBottomLeftRadius: theme.radius.md,
		borderBottomRightRadius: theme.radius.md,
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		transition: 'height 0.3s',
		paddingTop: 85,
		paddingLeft: 30,
	},
	open: { height: 620 },
	close: { height: 85 },
	menu: {
		transition: 'opacity 1s',
	},
	view: {
		opacity: 1,
		display: 'block',
	},
	hide: {
		opacity: 0,
		transition: 'opacity 0.1s',
		display: 'none',
	},
	nameWrapper: {
		marginBottom: 33,
	},
	mainMenu: {
		marginBottom: 50,
	},
	secondaryMenu: {
		marginTop: 33,
	},
	lang: {
		marginTop: 50,
	},
}))
