import { FC, memo } from 'react'
import { Anchor, Checkbox } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { offerLink } from '../../model/consts/offerConsts'

interface OfferCheckboxProps {
	accept: boolean
	setAccept: (accept: boolean) => void
}

export const OfferCheckbox: FC<OfferCheckboxProps> = memo((props) => {
	const { accept, setAccept } = props

	const { t } = useTranslation('payment')

	return (
		<Checkbox
			checked={accept}
			onChange={(event) => setAccept(event.currentTarget.checked)}
			radius="xs"
			label={
				<>
					{t('Согласен с условиями')}{' '}
					<Anchor href={offerLink()} target="_blank">
						{t('оферты')}
					</Anchor>
				</>
			}
		/>
	)
})

OfferCheckbox.displayName = 'OfferCheckbox'
