import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { StateSchema } from '@/app/providers/StoreProvider/config/StateSchema'

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions,
) => {
	const state: StateSchema = api.getState() as StateSchema

	const apiUrl = process.env.REACT_APP_API_URL

	// Определяем версию API на основе url
	const isV3 = typeof args === 'string' ? args.startsWith('/v3/') : args.url.startsWith('/v3/')
	const version = isV3 ? '/api/v3' : '/api/v2'
	const baseUrl = apiUrl + version

	// Удаляем дублирование версии в URL
	const url = typeof args === 'string' ? args.replace('/v3/', '/') : args.url.replace('/v3/', '/')

	const newArgs = typeof args === 'string' ? url : { ...args, url }

	return fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => {
			const token = state.user.userToken

			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	})(newArgs, api, extraOptions)
}

export const rtkApi = createApi({
	reducerPath: 'rtkApi',
	tagTypes: [
		'user',
		'releases',
		'subscr',
		'track',
		'bankCards',
		'trackList',
		'balance',
		'releaseInfo',
		'releaseImage',
		'subscription',
		'releaseChat',
		'prepaid',
	],
	baseQuery: dynamicBaseQuery,
	endpoints: () => ({}),
})
