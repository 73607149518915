import { FC, memo } from 'react'
import { ActionIcon, CopyButton, Group, Skeleton, Text } from '@mantine/core'
import { IconCheck, IconCopy } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

interface UserRefCodeButtonProps {
	refCode: string
	isLoading: boolean
}

export const UserRefCodeButton: FC<UserRefCodeButtonProps> = memo((props) => {
	const { refCode, isLoading } = props

	const { t } = useTranslation('profile')

	if (isLoading) {
		return <Skeleton w={'100%'} h={40} />
	}

	return (
		<CopyButton value={`https://yourtunes.net/?r=${refCode}`}>
			{({ copied, copy }) => (
				<Group noWrap align={'center'} position={'apart'}>
					<Text color={'gray.7'}>{t('Реферальная ссылка')}</Text>
					<Text fz={'sm'}>{copied ? 'Код скопирован' : `https://yourtunes.net/?r=${refCode}`}</Text>
					<ActionIcon color={'teal'} onClick={copy}>
						{copied ? <IconCheck color={'green'} stroke={1.3} /> : <IconCopy stroke={1.3} />}
					</ActionIcon>
				</Group>
			)}
		</CopyButton>
	)
})

UserRefCodeButton.displayName = 'UserRefCodeButton'
