import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SubscriptionStateScheme } from '../types/subscrSchema'
import { subscrApi } from '../../api/subscrApi'

const initialState: SubscriptionStateScheme = {
	requiredPrice: 0,
	promoError: '',
	description: '',
	date_end: '',
}

export const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(subscrApi.endpoints.showPriceSubscr.matchPending, (state) => {
				state.sum_without_promocode = undefined
				state.promoError = ''
			})
			.addMatcher(subscrApi.endpoints.showPriceSubscr.matchFulfilled, (state, { payload }) => {
				const { sum, description, date_end, sum_without_promocode } = payload

				state.requiredPrice = sum
				state.description = description
				state.date_end = date_end
				if (sum_without_promocode) state.sum_without_promocode = sum_without_promocode
			})
			.addMatcher(subscrApi.endpoints.showPriceSubscr.matchRejected, (state, { payload }: PayloadAction<any>) => {
				state.promoError = payload.data.message
			})
	},
})

export const { actions: subscriptionActions } = subscriptionSlice
export const { reducer: subscriptionReducer } = subscriptionSlice
