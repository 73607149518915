import classNames from 'classnames'
import { FC, memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Anchor } from '@mantine/core'
import { useStyles } from './MenuItem.styles'
import { MenuItemType } from '../../model/types/menu'
import { AppLink } from '@/shared/ui/AppLink/AppLink'
interface MenuItemProps {
	className?: string
	item: MenuItemType
}

export const MenuItem: FC<MenuItemProps> = memo((props) => {
	const { className, item } = props

	const { classes } = useStyles()
	const { pathname } = useLocation()

	const isActive = item.path === pathname

	if (item.inside) {
		return (
			<Anchor
				className={classNames(classes.menuItem, { [classes.active]: isActive }, [className])}
				href={item.path}
			>
				<item.Icon
					className={classNames(classes.icon, {
						[classes.activeIcon]: isActive,
					})}
				/>
				{item.text}
			</Anchor>
		)
	}

	return (
		<AppLink className={classNames(classes.menuItem, { [classes.active]: isActive }, [className])} to={item.path}>
			<item.Icon
				className={classNames(classes.icon, {
					[classes.activeIcon]: isActive,
				})}
			/>
			{item.text}
		</AppLink>
	)
})

MenuItem.displayName = 'MenuItem'
