import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	wrapper: {
		width: 500,
		[theme.fn.smallerThan('xs')]: {
			width: '100%',
		},
	},
}))
