import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	title: {
		fontSize: 32,
		lineHeight: '38px',
		fontWeight: 600,
	},
	text: {
		fontSize: 18,
		lineHeight: '24px',
	},
}))
