import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PaymentMethodsStateScheme } from '../types/paymentMethodsSchema'

const initialState: PaymentMethodsStateScheme = {
	cardUid: '',
}

export const paymentMethodsSlice = createSlice({
	name: 'paymentMethods',
	initialState,
	reducers: {
		setCardUid: (state, { payload }: PayloadAction<string>) => {
			state.cardUid = payload
		},
	},
	extraReducers: () => {},
})

export const { actions: paymentMethodsActions } = paymentMethodsSlice
export const { reducer: paymentMethodsReducer } = paymentMethodsSlice
