import { FC, memo } from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { IconDownload, IconFileTypePdf } from '@tabler/icons-react'
import { Anchor, Group } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from './BalanceAutoRow.styles'
import { BalanceListType } from '../../model/types/balance'
import 'dayjs/locale/ru'
interface BalanceAutoRowProps {
	item?: BalanceListType
	number?: number
	header?: boolean
}

export const BalanceAutoRow: FC<BalanceAutoRowProps> = memo((props) => {
	const { header, item, number } = props
	const { classes } = useStyles()
	const { t, i18n } = useTranslation('balance')

	const apiUrl = process.env.REACT_APP_API_URL

	const mods = {
		[classes.income]: item?.type === 0,
		[classes.expense]: item?.type !== 0,
	}

	const renderDescription = () => {
		return item?.comment
			? t(item.comment)
			: dayjs(item?.period)
					.locale(i18n.language)
					.format('MMMM YYYY')
	}

	if (header) {
		return (
			<div className={classNames(classes.balanceAutoRow, {}, classes.header)}>
				<div className={classes.number}>{t('№')}</div>
				<div className={classes.type}>{t('Статус')}</div>
				<div className={classes.sum}>{t('Сумма, ₽')}</div>
				<div className={classes.date}>{t('Дата')}</div>
				<div className={classes.description}>{t('Описание')}</div>
				<div className={classes.special}>Exel / PDF</div>
			</div>
		)
	}

	return (
		<div className={classes.balanceAutoRow}>
			<div className={classes.number}>{(number || number === 0) && number + 1}.</div>
			<div className={classNames(classes.type, mods)}>{item?.type === 0 ? t('Поступление') : t('Списание')}</div>
			<div className={classes.sum}>{item?.sum_print} ₽</div>
			<div className={classes.date}>{dayjs(item?.date).format('DD.MM.YYYY')}</div>
			<div className={classes.description}>{renderDescription()}</div>
			<div className={classes.special}>
				{item?.type === 0 && (item?.sum ?? 0) > 0 && (
					<Group noWrap>
						{item?.type === 0 && (
							<Anchor href={`${apiUrl}/balance/replenishment-detail/${item?.uid}`} target={'_blank'}>
								<IconDownload color={'teal'} />
							</Anchor>
						)}
						<Anchor href={`${apiUrl}/balance/pay-doc/${item?.uid}`} target={'_blank'}>
							<IconFileTypePdf color={'teal'} />
						</Anchor>
					</Group>
				)}
			</div>
		</div>
	)
})

BalanceAutoRow.displayName = 'BalanceAutoRow'
