import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AlertDetailsType, GlobalAlertModalStateScheme } from '../types/globalAlertModal'

const initialState: GlobalAlertModalStateScheme = {
	alertDetails: {
		message: '',
		title: '',
		type: 'error',
		link: undefined,
	},
}

export const globalAlertModalSlice = createSlice({
	name: 'globalAlertModal',
	initialState,
	reducers: {
		reducerAlertDetails: (state, action: PayloadAction<AlertDetailsType>) => {
			state.alertDetails = action.payload
		},
	},
	extraReducers: () => {},
})

export const { actions: globalAlertModalActions } = globalAlertModalSlice
export const { reducer: globalAlertModalReducer } = globalAlertModalSlice
