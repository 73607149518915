import classNames from 'classnames'
import { useMediaQuery } from '@mantine/hooks'
import { memo, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { GlobalStyle } from './styles/GlobalStyle'
import { ThemeProvider } from './styles/ThemeProvider'
import { AppRouter } from './providers/router'
import { PageLoader } from '@/widgets/PageLoader'
import { InternetConnectionChecker } from '@/widgets/InternetConnectionChecker'
import { GlobalAlertModal } from '@/widgets/GlobalAlertModal/ui/GlobalAlertModal'
import { Sidebar } from '@/widgets/Sidebar'
import { Navbar } from '@/widgets/Navbar'
import { getInited, useInitAuth } from '@/entities/User'
import { TrialPeriodEndModal } from '@/widgets/TrialPeriodEndModal'
import { NotificationsProvider } from '@/app/providers/NotificationsProvider'

const App = () => {
	const navbarOrSidebar = useMediaQuery('(min-width: 62em)')

	const inited = useSelector(getInited)
	const authUrl = process.env.REACT_APP_AUTH_URL

	const { error } = useInitAuth()

	if (!inited) {
		return (
			<ThemeProvider>
				<PageLoader />
			</ThemeProvider>
		)
	}

	if (error) {
		const currentUrl = window.location.href
		if ('data' in error) window.location.href = `${authUrl}?returnUrl=${currentUrl}`
	}

	return (
		<ThemeProvider>
			<Suspense fallback={<PageLoader />}>
				<NotificationsProvider>
					<GlobalStyle />
					<div className={classNames('app', {}, [])}>
						{/* Глобальное модальное окно с ошибками, для взаимодействия используйте redux */}
						<GlobalAlertModal />
						{/* Модальное окно с логикой проверки окончания триальной подписки */}
						<TrialPeriodEndModal />
						<div className="content-page">
							{navbarOrSidebar ? <Sidebar /> : <Navbar />}
							<InternetConnectionChecker>
								<AppRouter />
							</InternetConnectionChecker>
						</div>
					</div>
				</NotificationsProvider>
			</Suspense>
		</ThemeProvider>
	)
}

export default memo(App)
