import { Modal, Button, Text, Box, Group } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { useStyles } from './TrialPeriodEndModal.styles'
import { useGetSubscrData } from '@/entities/Subscr'
import { PaymentSubscriptionScreen } from '@/widgets/PaymentSubscription'
import { useResponsive } from '@/shared/hooks/useResponsive/useResponsive'
export const TrialPeriodEndModal = () => {
	const { classes } = useStyles()

	const [openedModalTrial, { open: openTrial, close: closeTrial }] = useDisclosure()
	const [openedModalPaySubscr, { open: openPaySubscr, close: closePaySubscr }] = useDisclosure()
	const { data } = useGetSubscrData()

	const { xs } = useResponsive()

	useEffect(() => {
		if (data?.demo) {
			const currentDate = dayjs()
			const dateEnd = dayjs(data.date_end)
			const daysDiff = dateEnd.diff(currentDate, 'day', true)

			if (daysDiff < 1 && daysDiff >= 0) {
				const lastShown = localStorage.getItem('trialModalLastShown')
				const now = dayjs()

				if (!lastShown || now.diff(dayjs(lastShown), 'minute') >= 30) {
					openTrial()
					localStorage.setItem('trialModalLastShown', now.toISOString())
				}
			}
		}
	}, [data, openTrial])

	const handlePaySubscr = () => {
		closeTrial()
		openPaySubscr()
	}

	return (
		<>
			<Modal
				opened={openedModalTrial}
				onClose={closeTrial}
				title={<Box className={classes.title}>Приветствуем вас!</Box>}
				centered
				size={'xl'}
				padding={xs ? 12 : 32}
				closeOnClickOutside={false}
			>
				<Text className={classes.text} mb={12}>
					Мы надеемся, что вы успели воспользоваться всеми возможностями нашего интерфейса.
				</Text>
				<Text className={classes.text} mb={12}>
					Напоминаем, что ваш тестовый период подходит к концу. Завтра доступ к выгрузке музыкальных
					произведений будет приостановлен. Для продолжения работы с нашим сервисом потребуется оплата за
					полный период. Если у вас возникли вопросы, пожалуйста, свяжитесь со службой технической поддержки —
					мы будем рады помочь.
				</Text>
				<Text className={classes.text}>Спасибо, что вы с нами!</Text>
				<Text className={classes.text}>С уважением, Сервис YOURTUNES</Text>
				<Group mt={48}>
					<Button onClick={handlePaySubscr} color={'dark'} w={xs ? '100%' : 200}>
						Оплатить сейчас
					</Button>
					<Button onClick={closeTrial} variant={'outline'} color={'dark'} w={xs ? '100%' : 150}>
						Закрыть
					</Button>
				</Group>
			</Modal>
			<Modal opened={openedModalPaySubscr} onClose={closePaySubscr} centered size={'auto'}>
				<PaymentSubscriptionScreen onClose={closePaySubscr} />
			</Modal>
		</>
	)
}
