import { FC, memo, useEffect } from 'react'
import { Badge, Group, Skeleton, Text } from '@mantine/core'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useStyles } from './SubscriptionPriceInfo.styles'
import { useShowPriceSubscr } from '../../api/subscrApi'
import { getDateEnd } from '../../model/selectors/getDateEnd/getDateEnd'
import { getDescription } from '../../model/selectors/getDescription/getDescription'
import { getSumWithoutPromo } from '../../model/selectors/getSumWithoutPromo/getSumWithoutPromo'
import { getRequiredPrice } from '@/entities/Subscr'
import { TextDescriptionRight } from '@/shared/ui/TextDescriptionRight/TextDescriptionRight'
import { useGlobalAlertModal } from '@/widgets/GlobalAlertModal'
import { getDurationSubscr, getIdSubscr } from '@/widgets/PaymentSubscription'

interface SubscriptionPriceInfoProps {
	selectedTariffId?: number
	promoCode?: string
}

export const SubscriptionPriceInfo: FC<SubscriptionPriceInfoProps> = memo((props) => {
	const { promoCode } = props
	const { classes } = useStyles()
	const { t, i18n } = useTranslation('payment')

	const { openGlobalAlert } = useGlobalAlertModal()
	const [showPrice, { isLoading }] = useShowPriceSubscr()

	const price = useSelector(getRequiredPrice)
	const dateEnd = useSelector(getDateEnd)
	const description = useSelector(getDescription)
	const priceWithoutPromo = useSelector(getSumWithoutPromo)
	const idSubscr = useSelector(getIdSubscr)
	const durationSubscr = useSelector(getDurationSubscr)

	const fetchCostSubscr = async () => {
		try {
			await showPrice({
				tarif: Number(idSubscr),
				promocode: promoCode,
				count: Number(durationSubscr),
			}).unwrap()
		} catch (e: any) {
			openGlobalAlert({
				type: 'error',
				title: t('Ошибка'),
				message: e.data.message ?? t('Неизвестная ошибка, пожалуйста обратитесь в тех.поддержку!'),
			})
		}
	}

	useEffect(() => {
		fetchCostSubscr()
	}, [promoCode])

	if (isLoading) {
		return (
			<>
				<Skeleton w={'100%'} h={40} />
				<Skeleton w={'100%'} h={40} />
				<Skeleton w={'100%'} h={40} />
				<Skeleton w={'100%'} h={40} />
			</>
		)
	}

	const dateEndPrint = dayjs(dateEnd).locale(i18n.language).format('DD.MM.YYYY')

	const renderSum = () => {
		if (priceWithoutPromo) {
			const percentageDifference = Math.ceil(((priceWithoutPromo - price) / priceWithoutPromo) * 100)

			return (
				<>
					<TextDescriptionRight
						leftText={t('Стоимость тарифа:')}
						rightText={`${priceWithoutPromo} ${t('руб.')}`}
					/>
					<Badge color={'teal'}>
						{t('Скидка с учетом промокода составляет')} - {percentageDifference}%
					</Badge>
					<Group align={'center'} position={'apart'} className={classes.finalSum}>
						<Text>{t('Итого к оплате:')}</Text>
						<Text>
							{price} {t('руб.')}
						</Text>
					</Group>
				</>
			)
		}

		if (!price) {
			return null
		}

		return (
			<>
				<TextDescriptionRight leftText={t('Стоимость тарифа:')} rightText={`${price} ${t('руб.')}`} />
				<Group align={'center'} position={'apart'} className={classes.finalSum}>
					<Text>{t('Итого к оплате:')}</Text>
					<Text>
						{price} {t('руб.')}
					</Text>
				</Group>
			</>
		)
	}

	return (
		<>
			<TextDescriptionRight leftText={t('Название:')} rightText={t(description)} />
			<TextDescriptionRight leftText={t('Действителен до:')} rightText={dateEndPrint} />
			{renderSum()}
		</>
	)
})

SubscriptionPriceInfo.displayName = 'SubscriptionPriceInfo'
