import { FC, memo } from 'react'
import { Modal } from '@mantine/core'
import { useSelector } from 'react-redux'
import { paymentSubscriptionActions } from '../../model/slices/paymentSubscriptionSlice'
import { getOpenedModal } from '../../model/selectors/getOpenedModal/getOpenedModal'
import { PaymentSubscriptionScreen } from '../PaymentSubscriptionScreen/PaymentSubscriptionScreen'
import { useStyles } from './PaymentSubscriptionModal.styles'
import { TariffListSelect } from '@/entities/Tariff'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'
import { useGetSubscrData } from '@/entities/Subscr'
import { getIdSubscr } from '@/widgets/PaymentSubscription/model/selectors/getIdSubscr/getIdSubscr'

interface PaymentSubscriptionModalProps {
	releaseUID?: string
}

export const PaymentSubscriptionModal: FC<PaymentSubscriptionModalProps> = memo((props) => {
	const { releaseUID } = props
	const dispatch = useAppDispatch()
	const { classes } = useStyles()
	const openedModal = useSelector(getOpenedModal)
	const selectedTariffID = useSelector(getIdSubscr)
	const { data } = useGetSubscrData()

	const handleCloseModal = () => {
		dispatch(paymentSubscriptionActions.closeModal())
		dispatch(paymentSubscriptionActions.setIdSubscr(null))
	}

	if (!data) return null

	const { tarif_id, active, count } = data

	return (
		<Modal centered opened={openedModal} onClose={handleCloseModal} size={'auto'}>
			{!selectedTariffID ? (
				<TariffListSelect
					currentTariffId={String(tarif_id)}
					currentDuration={String(count)}
					isActive={active}
				/>
			) : (
				<PaymentSubscriptionScreen onClose={handleCloseModal} releaseUID={releaseUID} />
			)}
		</Modal>
	)
})

PaymentSubscriptionModal.displayName = 'PaymentSubscriptionModal'
