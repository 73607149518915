import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	emptyList: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 150,
		height: '100%',
	},
	text: {
		fontWeight: 500,
		marginTop: 10,
		fontSize: theme.fontSizes.lg,
	},
}))
