import { Skeleton, Stack, Text } from '@mantine/core'
import { FC, memo } from 'react'
import { useStyles } from './UserNameEmailCard.styles'
import { useGetUserData } from '../../api/userAuthApi'

interface UserNameEmailCardProps {
	className?: string
}

export const UserNameEmailCard: FC<UserNameEmailCardProps> = memo(({ className }) => {
	const { classes } = useStyles()

	const { data, isLoading } = useGetUserData()

	if (isLoading) {
		return (
			<Stack className={className}>
				<Skeleton h={30} w={'100%'} />
				<Skeleton h={30} w={'100%'} />
			</Stack>
		)
	}

	if (!data) return null

	const { display_name, email } = data.result

	return (
		<Stack className={className}>
			<Text className={classes.name}>{display_name}</Text>
			<Text className={classes.email}>{email}</Text>
		</Stack>
	)
})

UserNameEmailCard.displayName = 'UserNameEmailCard'
