import { configureStore } from '@reduxjs/toolkit'
import type { ReducersMapObject, Reducer, CombinedState } from '@reduxjs/toolkit'
import { createReducerManager } from './reducerManager'
import { StateSchema } from './StateSchema'
import { rtkApi } from '@/shared/api/rtkApi'
import { authApi } from '@/shared/api/authApi'
import { payApi } from '@/shared/api/payApi'
import { userReducer } from '@/entities/User'
import { globalAlertModalReducer } from '@/widgets/GlobalAlertModal'
import { subscriptionReducer } from '@/entities/Subscr'
import { paymentSubscriptionReducer } from '@/widgets/PaymentSubscription'

export function createReduxStore(initialState?: StateSchema, asyncReducers?: ReducersMapObject<StateSchema>) {
	const rootReducers: ReducersMapObject<StateSchema> = {
		...asyncReducers,
		user: userReducer,
		globalAlertModal: globalAlertModalReducer,
		subscription: subscriptionReducer,
		paymentSubscription: paymentSubscriptionReducer,
		[rtkApi.reducerPath]: rtkApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
		[payApi.reducerPath]: payApi.reducer,
	}

	const reducerManager = createReducerManager(rootReducers)

	const store = configureStore({
		reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
		preloadedState: initialState,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(rtkApi.middleware).concat(authApi.middleware).concat(payApi.middleware),
	})

	// @ts-ignore
	store.reducerManager = reducerManager

	return store
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch']
