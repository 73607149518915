import { FC, memo } from 'react'
import { Box, Checkbox, LoadingOverlay, Skeleton, Text } from '@mantine/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { paymentWithBalanceActions } from '../../model/slices/paymentWithBalanceSlice'
import { getIsBalancePaymentEnabled } from '../../model/selectors/getIsBalancePaymentEnabled/getIsBalancePaymentEnabled'
import { useGetBalance } from '@/entities/Balance'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'

interface PaymentWithBalanceCheckboxProps {
	requiredPrice: number
}

export const PaymentWithBalanceCheckbox: FC<PaymentWithBalanceCheckboxProps> = memo((props) => {
	const { requiredPrice } = props
	const dispatch = useAppDispatch()

	const { t } = useTranslation('payment')

	const isBalancePaymentEnabled = useSelector(getIsBalancePaymentEnabled)

	const { data, isLoading, isFetching } = useGetBalance()

	const handlePayFromBalance = (isFromBalance: boolean) => {
		dispatch(paymentWithBalanceActions.changeBalancePaymentEnabled(isFromBalance))
	}

	if (isLoading) {
		return <Skeleton h={40} width={'60%'} />
	}

	if (!data) return null

	if (requiredPrice === 0) return null

	const { balance_print, balance } = data.result

	if (balance === 0) {
		return <Text>{t('Баланс пуст')}</Text>
	}

	if (balance > requiredPrice) {
		return (
			<Box pos={'relative'}>
				<LoadingOverlay visible={isFetching} loaderProps={{ variant: 'dots' }} />
				<Checkbox
					label={`${t('На балансе')} ${balance_print} ${t('руб.')} ${t('Оплатить с баланса?')}`}
					radius={'xs'}
					checked={isBalancePaymentEnabled}
					onChange={(event) => handlePayFromBalance(event.currentTarget.checked)}
				/>
			</Box>
		)
	}

	return (
		<Text>
			{t('Денег на балансе недостаточно')} - {balance_print} {t('руб.')}{' '}
		</Text>
	)
})

PaymentWithBalanceCheckbox.displayName = 'PaymentWithBalanceCheckbox'
