import { FC, memo, ReactNode } from 'react'
import classNames from 'classnames'
import { Alert, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from './AlertYt.styles'

interface AlertYtProps {
	className?: string
	type?: 'success' | 'error' | 'info'
	title?: string
	children?: string | ReactNode
	onClose?: (value: string) => void
	withCloseButton?: boolean
	variant?: 'light' | 'filled' | 'outline'
	maw?: number
	fz?: number
}

export const AlertYt: FC<AlertYtProps> = memo((props) => {
	const {
		className,
		type = 'error',
		title,
		children,
		onClose,
		withCloseButton = true,
		variant = 'light',
		maw,
		fz,
	} = props
	const { classes } = useStyles()
	const { t } = useTranslation()

	const renderTitle = () => {
		switch (type) {
			case 'error':
				return t('Ошибка')
			case 'info':
				return t('Внимание')
			case 'success':
				return t('Успешно')
		}
	}

	const renderColor = () => {
		switch (type) {
			case 'error':
				return 'red'
			case 'info':
				return 'blue'
			case 'success':
				return 'green'
		}
	}

	return (
		<Alert
			className={classNames(classes.alert, {}, className)}
			title={title ?? renderTitle() + '!'}
			color={renderColor()}
			withCloseButton={withCloseButton}
			onClose={onClose && (() => onClose(''))}
			variant={variant}
			radius={'lg'}
			maw={maw}
		>
			<Text fz={fz}>{children}</Text>
		</Alert>
	)
})

AlertYt.displayName = 'AlertYt'
