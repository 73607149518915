import { FC, memo } from 'react'
import { Group, Anchor, Skeleton } from '@mantine/core'
import { ReactComponent as TelegramSVG } from '@/shared/assets/icons/icon_telegram.svg'
import { ReactComponent as FacebookSVG } from '@/shared/assets/icons/icon_facebook.svg'
import { ReactComponent as InstagramSVG } from '@/shared/assets/icons/icon_instagram.svg'
import { ReactComponent as VkSVG } from '@/shared/assets/icons/icon_vk.svg'

type Socials = {
	telegram: string
	vk: string
	facebook: string
	instagram: string
}
interface UserSocialsProps {
	socials: Socials
	isLoading: boolean
}
export const UserSocials: FC<UserSocialsProps> = memo((props) => {
	const { socials, isLoading } = props

	const { vk, facebook, instagram, telegram } = socials

	if (isLoading) {
		return <Skeleton h={33} w={'100%'} />
	}

	return (
		<Group align="center">
			{telegram && (
				<Anchor
					href={`https://t.me/${telegram.startsWith('@') ? telegram.slice(1) : telegram}`}
					target={'_blank'}
				>
					<TelegramSVG />
				</Anchor>
			)}
			{vk && (
				<Anchor href={vk} target="_blank">
					<VkSVG />
				</Anchor>
			)}
			{facebook && (
				<Anchor href={facebook} target="_blank">
					<FacebookSVG />
				</Anchor>
			)}
			{instagram && (
				<Anchor href={instagram} target="_blank">
					<InstagramSVG />
				</Anchor>
			)}
		</Group>
	)
})

UserSocials.displayName = 'UserSocials'
