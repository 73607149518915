import { FC, ReactNode, useEffect, useState } from 'react'
import { Button, Modal, Stack, Title, Text } from '@mantine/core'
import { useGetMessages, useMarkMessageAsRead } from '../api/notificationsApi'
import { NotificationsMessage } from '../model/types/notificationsSchema'
import { useStyles } from './NotificationsProvider.styles'

interface NotificationsProviderProps {
	children: ReactNode
}

export const NotificationsProvider: FC<NotificationsProviderProps> = (props) => {
	const { children } = props

	const { classes } = useStyles()

	const [isModalOpen, setModalOpen] = useState<boolean>(false)
	const [currentMessage, setCurrentMessage] = useState<NotificationsMessage | null>()

	const { data } = useGetMessages()
	const [markMessageAsRead] = useMarkMessageAsRead()

	useEffect(() => {
		if (data && data.unread_count > 0) {
			setCurrentMessage(data.messages[0])
			setModalOpen(true)
		}
	}, [data])

	const handleReadMessage = (id?: string) => {
		if (id) {
			markMessageAsRead(id)
			setModalOpen(false)
		}
	}

	const formatMessage = (message: string) => {
		return message.replace(/\n/g, '<br>')
	}

	return (
		<>
			{children}
			{currentMessage && (
				<Modal
					opened={isModalOpen}
					onClose={() => handleReadMessage(currentMessage?.uid)}
					closeOnClickOutside={false}
					centered
					withCloseButton={false}
				>
					<Stack>
						<Title order={3}>{currentMessage.title}</Title>
						<div>
							<Text
								className={classes.text}
								lh={1.3}
								dangerouslySetInnerHTML={{ __html: formatMessage(currentMessage.message) }}
							/>
						</div>
						<Button onClick={() => handleReadMessage(currentMessage?.uid)}>Прочитано</Button>
					</Stack>
				</Modal>
			)}
		</>
	)
}
