import { FC, memo, useEffect } from 'react'
import { useForm } from '@mantine/form'
import { ActionIcon, HoverCard, Text, TextInput, Button } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useStyles } from './PaymentWithPromo.styles'
import { getPaymentPromo } from '../../model/selectors/getPaymentPromo/getPaymentPromo'
import { paymentWithPromoActions } from '../../model/slices/paymentWithPromoSlice'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'
import { AlertYt } from '@/shared/ui/AlertYt/AlertYt'

interface PaymentWithPromoProps {
	promoError: string
}

export const PaymentWithPromo: FC<PaymentWithPromoProps> = memo((props) => {
	const { promoError } = props
	const { classes } = useStyles()

	const { t } = useTranslation('payment')

	const dispatch = useAppDispatch()

	const paymentPromo = useSelector(getPaymentPromo)

	const form = useForm<{ promo: string }>({
		initialValues: { promo: '' },
		validate: {
			promo: (value) => (!value.trim() ? t('Введите промокод') : null),
		},
	})

	const handleConfirmedPromo = (values: { promo: string }) => {
		dispatch(paymentWithPromoActions.setPromo(values.promo))
	}

	const handleClearPromo = () => {
		form.setFieldValue('promo', '')
		dispatch(paymentWithPromoActions.setPromo(''))
	}

	useEffect(() => {
		return () => {
			handleClearPromo()
		}
	}, [])

	const renderButton = () => {
		if (paymentPromo) {
			return (
				<ActionIcon color={'red'} variant={'light'} onClick={handleClearPromo}>
					<IconX />
				</ActionIcon>
			)
		} else {
			return (
				<HoverCard shadow={'xl'} width={150}>
					<HoverCard.Target>
						{/*	<ActionIcon type={'submit'} variant={'filled'} color={'teal'}>
						<IconCheck />
					</ActionIcon>*/}
						<Button color={'teal'} compact variant={'light'} type={'submit'} size={'sm'}>
							{t('Применить')}
						</Button>
					</HoverCard.Target>
					<HoverCard.Dropdown>
						<Text fz={'xs'}>{t('Введите промокод и примените его')}</Text>
					</HoverCard.Dropdown>
				</HoverCard>
			)
		}
	}

	return (
		<form onSubmit={form.onSubmit((value) => handleConfirmedPromo(value))}>
			<TextInput
				label={t('Промокод')}
				placeholder={t('Введите промокод')}
				rightSectionWidth={paymentPromo ? undefined : '100px'}
				rightSection={renderButton()}
				{...form.getInputProps('promo')}
			/>
			{promoError && <AlertYt withCloseButton={false}>{promoError}</AlertYt>}
		</form>
	)
})

PaymentWithPromo.displayName = 'PaymentWithPromo'
