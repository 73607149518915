import { createStyles, rem } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	sum: {
		fontSize: rem(32),
		lineHeight: rem(38),
		fontWeight: 600,
		marginBottom: 5,
	},
	prompt: {
		fontSize: theme.fontSizes.sm,
		color: theme.colors.gray[6],
	},
}))
