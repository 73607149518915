import classNames from 'classnames'
import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { useStyles } from './AppLink.styles'

interface AppLinkProps {
	className?: string
	children: ReactNode
	to: string
}

export const AppLink = (props: AppLinkProps) => {
	const { className, children, to } = props

	const { classes } = useStyles()

	return (
		<NavLink className={classNames(classes.appLink, {}, [className])} to={to}>
			{children}
		</NavLink>
	)
}
