import { FC, memo } from 'react'
import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from './ActiveSubscrToggleButton.styles'
import { useToggleEndSubscr } from '../../api/updateSubscrApi'
import { useGlobalAlertModal } from '@/widgets/GlobalAlertModal'

interface ActiveSubscrToggleButtonProps {
	end: boolean
}

export const ActiveSubscrToggleButton: FC<ActiveSubscrToggleButtonProps> = memo((props) => {
	const { end } = props
	const { t } = useTranslation('profile')
	const { classes } = useStyles()

	const { openGlobalAlert } = useGlobalAlertModal()

	const [toggleEndSubscr, { isLoading }] = useToggleEndSubscr()

	const handleToggleEndSubscr = async () => {
		try {
			await toggleEndSubscr().unwrap()
		} catch (e: any) {
			openGlobalAlert({
				type: 'error',
				title: t('Ошибка'),
				message: e.data.message ?? t('Неизвестная ошибка, пожалуйста обратитесь в тех.поддержку'),
			})
		}
	}

	return (
		<Button
			variant={'subtle'}
			compact
			onClick={handleToggleEndSubscr}
			loading={isLoading}
			color={end ? 'teal' : 'red'}
		>
			{end ? t('Возобновить подписку') : t('Отменить подписку')}
		</Button>
	)
})

ActiveSubscrToggleButton.displayName = 'ActiveSubscrToggleButton'
