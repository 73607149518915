import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	balanceList: {
		padding: '16px 8px',
		[theme.fn.smallerThan('sm')]: {
			['& > *']: {
				borderBottom: `1px solid ${theme.colors.gray[3]}`,
			},
			['& > *:last-child']: {
				borderBottom: 'none',
			},
		},
	},
}))
