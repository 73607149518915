import { FC, memo, ReactNode } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useStyles } from './EmptyList.styles'

interface EmptyListProps {
	className?: string
	text?: string
	Icon?: ReactNode
}

export const EmptyList: FC<EmptyListProps> = memo((props) => {
	const { className, text, Icon } = props
	const { classes } = useStyles()
	const { t } = useTranslation()

	return (
		<div className={classNames(classes.emptyList, {}, className)}>
			{Icon}
			<div className={classes.text}>{text ?? t('Список пуст')}</div>
		</div>
	)
})

EmptyList.displayName = 'EmptyList'
