import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	name: {
		fontWeight: 600,
		fontSize: 16,
	},
	email: {
		fontSize: 14,
		color: theme.colors.gray[6],
	},
}))
