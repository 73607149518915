import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions,
) => {
	const authUrl = process.env.REACT_APP_AUTH_URL

	const dynamicUrl = authUrl + '/api/v1'

	return fetchBaseQuery({
		baseUrl: dynamicUrl,
		credentials: 'include',
	})(args, api, extraOptions)
}

export const authApi = createApi({
	reducerPath: 'authApi',
	tagTypes: ['userData'],
	baseQuery: dynamicBaseQuery,
	endpoints: (build) => ({}),
})
