import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PaymentWithPromoStateSchema } from '../types/PaymentWithPromoSchema'

const initialState: PaymentWithPromoStateSchema = {
	paymentPromo: '',
}

export const paymentWithPromoSlice = createSlice({
	name: 'PaymentWithPromo',
	initialState,
	reducers: {
		setPromo: (state, { payload }: PayloadAction<string>) => {
			state.paymentPromo = payload
		},
	},
	extraReducers: () => {},
})

export const { actions: paymentWithPromoActions } = paymentWithPromoSlice
export const { reducer: paymentWithPromoReducer } = paymentWithPromoSlice
