import classNames from 'classnames'
import { FC } from 'react'
import { Flex, ScrollArea } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useStyles } from './Sidebar.styles'
import { UserSidebarCard } from '@/entities/User'
import { MenuList } from '@/widgets/Menu'
import { useMainMenuItems, useSecondMenuItems } from '@/shared/configs/menuConfig/menuConfig'
import { LangSwitcher } from '@/widgets/LangSwitcher'

interface SidebarProps {
	className?: string
}

export const Sidebar: FC<SidebarProps> = (props) => {
	const { className } = props

	const { classes } = useStyles()
	const maxHeight = useMediaQuery('(max-height: 770px)')
	const isDesktop = useMediaQuery('(min-width: 992px)')

	const mainMenuItemsList = useMainMenuItems()
	const secondaryMenuItemsList = useSecondMenuItems()

	return (
		<div className={classNames(classes.sidebar, {}, [className])}>
			<Flex pos="fixed" left={0} w="286px" direction="column" h="100%">
				<div className={classes.ava}>
					<UserSidebarCard />
				</div>
				<div className={maxHeight && isDesktop ? classes.shortMenuList : classes.menuList}>
					<ScrollArea>
						<MenuList className={classes.mainMenu} dataMenuItems={mainMenuItemsList} />
						<MenuList className={classes.mainMenu} dataMenuItems={secondaryMenuItemsList} />
					</ScrollArea>
				</div>
				<div className={classes.lang}>
					<LangSwitcher />
				</div>
			</Flex>
		</div>
	)
}
