import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	icon: {
		transition: '0.3s all',
		'&:hover': {
			transform: 'scale(1.2)',
			opacity: '0.8',
		},
	},
}))
