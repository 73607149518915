import { BankCardsResponse } from '../model/types/paymentMethodsSchema'
import { payApi } from '@/shared/api/payApi'

const paymentMethodsApi = payApi.injectEndpoints({
	endpoints: (build) => ({
		getBankCards: build.query<BankCardsResponse, void>({
			query: () => ({
				url: '/list-cards',
			}),
			providesTags: (response, error, arg) =>
				response?.result ? response.result.list.map(({ guid }) => ({ type: 'cards', id: guid })) : [],
		}),
		deleteBankCard: build.mutation<void, string>({
			query: (guid) => ({
				url: `/delete-card/${guid}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, guid) => [{ type: 'cards', id: guid }],
		}),
	}),
})

export const useGetBankCards = paymentMethodsApi.useGetBankCardsQuery
export const useDeleteBankCard = paymentMethodsApi.useDeleteBankCardMutation

export const { getBankCards } = paymentMethodsApi.endpoints
