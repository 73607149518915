import { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { Button, Center, Grid, Group, Radio, Text, Skeleton, Switch, Badge } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useGetTariffList } from '../../api/tariffApi'
import { TariffCard } from '../TariffCard/TariffCard'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'
import { paymentSubscriptionActions } from '@/widgets/PaymentSubscription'

interface TariffListSelectProps {
	currentDuration: string
	currentTariffId: string
	isActive: boolean
}

export const TariffListSelect: FC<TariffListSelectProps> = memo((props) => {
	const { currentTariffId, currentDuration, isActive } = props

	const { t } = useTranslation('payment')

	const dispatch = useAppDispatch()

	const [isYearsSubscr, setIsYearsSubscr] = useState<boolean>(false)
	const [selectedTariffId, setSelectedTariffId] = useState<string | null>(currentTariffId)

	const { data, isLoading } = useGetTariffList()

	const handleCurrentId = () => {
		dispatch(paymentSubscriptionActions.setIdSubscr(selectedTariffId))
	}

	const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
		setIsYearsSubscr(event.currentTarget.checked)
		dispatch(paymentSubscriptionActions.setDuration(event.currentTarget.checked ? '12' : '1'))
	}

	useEffect(() => {
		if (currentDuration === '12') {
			setIsYearsSubscr(true)
			dispatch(paymentSubscriptionActions.setDuration('12'))
		} else {
			setIsYearsSubscr(false)
			dispatch(paymentSubscriptionActions.setDuration('1'))
		}
	}, [currentDuration])

	if (isLoading) {
		return (
			<Grid>
				<Grid.Col md={4}>
					<Skeleton width={'100%'} miw={250} height={300} />
				</Grid.Col>
				<Grid.Col md={4}>
					<Skeleton width={'100%'} miw={250} height={300} />
				</Grid.Col>
				<Grid.Col md={4}>
					<Skeleton width={'100%'} miw={250} height={300} />
				</Grid.Col>
				<Grid.Col mt={40}>
					<Center>
						<Skeleton width={'70%'} height={40} />
					</Center>
				</Grid.Col>
			</Grid>
		)
	}

	if (!data) return null

	const { result: tariffList } = data

	const cards = tariffList.slice(0, 3).map((tariff, index) => {
		return (
			<Grid.Col md={4} key={index} pos={'relative'} pb={150}>
				<TariffCard tariff={tariff} isYearsSubscr={isYearsSubscr} />
			</Grid.Col>
		)
	})

	return (
		<div>
			<Group position={'right'} mb={30}>
				<Text>Месяц</Text>
				<Switch checked={isYearsSubscr} onChange={handleSwitchChange} />
				<Text>Год</Text>
				<Badge color={isYearsSubscr ? 'teal.8' : 'dark.1'}>Месяц в подарок</Badge>
			</Group>

			<Radio.Group value={selectedTariffId as string} name="tariffId" onChange={setSelectedTariffId}>
				<Grid justify="center">{cards}</Grid>
				<Center>
					<Button
						maw={400}
						fullWidth
						color={'dark'}
						onClick={handleCurrentId}
						disabled={
							currentTariffId === selectedTariffId &&
							(isYearsSubscr ? currentDuration === '12' : currentDuration === '1')
						}
					>
						{currentTariffId === selectedTariffId &&
						(isYearsSubscr ? currentDuration === '12' : currentDuration === '1')
							? t('Действующий тариф')
							: t('Оплатить тариф')}
					</Button>
				</Center>
			</Radio.Group>
		</div>
	)
})

TariffListSelect.displayName = 'TariffListSelect'
