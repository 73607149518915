import { Skeleton, Text } from '@mantine/core'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './BalanceCard.styles'
import { canWithdraw } from '../../model/consts/balanceConsts'
import { WithdrawalMoneyButton } from '@/features/withdrawalMoney'
import { useGetBalance } from '@/entities/Balance'

export const BalanceCard = memo((props) => {
	const { t } = useTranslation('balance')

	const { classes } = useStyles()

	const { data: balanceData, isLoading: isLoadingBalance } = useGetBalance()

	if (isLoadingBalance) {
		return <Skeleton w={'100%'} h={80} />
	}

	if (!balanceData) return null

	const { balance, balance_print } = balanceData.result

	return (
		<>
			<Text mb={12}>Баланс:</Text>
			<Text className={classes.sum}>{balance_print} ₽</Text>
			{canWithdraw(balance) ? (
				<Text className={classes.prompt}>
					{t('Доступно к выводу')} {balance_print} ₽
				</Text>
			) : (
				<Text className={classes.prompt}>{t('Минимальная сумма для вывода 150 ₽')}</Text>
			)}
			<WithdrawalMoneyButton balanceData={balanceData?.result} isLoading={isLoadingBalance} />
		</>
	)
})

BalanceCard.displayName = 'BalanceCard'
