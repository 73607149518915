import { createSlice } from '@reduxjs/toolkit'
import { UserStateScheme } from '../types/userSchema'
import { userAuthApi } from '../../api/userAuthApi'

const initialState: UserStateScheme = {
	inited: false,
	userToken: '',
	isAuth: false,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(userAuthApi.endpoints.initAuth.matchFulfilled, (state, { payload }) => {
				const { token } = payload.result

				state.inited = true
				state.userToken = token
				state.isAuth = true
			})
			.addMatcher(userAuthApi.endpoints.initAuth.matchRejected, (state) => {
				state.inited = true
			})
			.addMatcher(userAuthApi.endpoints.logoutUser.matchFulfilled, (state) => {
				state.userToken = ''
				state.isAuth = false
			})
	},
})

export const { actions: userActions } = userSlice
export const { reducer: userReducer } = userSlice
