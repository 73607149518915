import classNames from 'classnames'
import { FC, useEffect } from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Avatar, Skeleton } from '@mantine/core'
import { useLocation } from 'react-router-dom'
import { useStyles } from './Navbar.styles'
import { MenuNavbar } from '@/widgets/Menu'
import { HamburgerButton } from '@/shared/ui/HamburgerButton/HamburgerButton'
import { useGetUserData } from '@/entities/User'

interface NavbarProps {
	className?: string
}

export const Navbar: FC<NavbarProps> = (props) => {
	const { className } = props

	const { pathname } = useLocation()

	const [opened, { toggle, close }] = useDisclosure(false)

	const { classes } = useStyles()

	const { data, isLoading } = useGetUserData()

	useEffect(() => {
		close()
	}, [pathname])

	return (
		<div className={classNames(classes.navbar, {}, [className])}>
			<div className={classes.navbarHeader}>
				{isLoading ? (
					<Skeleton className={classes.ava} />
				) : (
					<Avatar className={classes.ava} src={data?.result.ava} />
				)}
				<HamburgerButton opened={opened} onClick={toggle} />
			</div>
			<MenuNavbar opened={opened} />
		</div>
	)
}
