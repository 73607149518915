import { FC, memo, ReactNode } from 'react'
import { Tabs } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from './PaymentMenu.styles'

interface PaymentMenuProps {
	leftTitle?: string
	rightTitle?: string
	LeftBlock: ReactNode
	RightBlock: ReactNode
}

export const PaymentMenu: FC<PaymentMenuProps> = memo((props) => {
	const { t } = useTranslation('payment')
	const { leftTitle = t('Оплата из РФ'), LeftBlock, RightBlock, rightTitle = t('Оплата не из РФ') } = props
	const { classes } = useStyles()
	return (
		<div className={classes.wrapper}>
			<Tabs defaultValue="left" radius="md" keepMounted={false}>
				<Tabs.List grow position="apart" mb={40}>
					<Tabs.Tab value="left">{leftTitle}</Tabs.Tab>
					<Tabs.Tab value="right">{rightTitle}</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="left">{LeftBlock}</Tabs.Panel>
				<Tabs.Panel value="right">{RightBlock}</Tabs.Panel>
			</Tabs>
		</div>
	)
})

PaymentMenu.displayName = 'PaymentMenu'
