import { memo, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { routeConfig } from 'shared/configs/routeConfig/routeConfig'
import { PageLoader } from 'widgets/PageLoader'
import { ScrollToTop } from './ScrollToTop'

const AppRouter = () => (
	<div className="page-wrapper">
		<Suspense fallback={<PageLoader />}>
			<ScrollToTop />
			<Routes>
				{Object.values(routeConfig).map(({ element, path }) => (
					<Route key={path} path={path} element={element} />
				))}
			</Routes>
		</Suspense>
	</div>
)

export default memo(AppRouter)
