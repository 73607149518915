import { useTranslation } from 'react-i18next'
import {
	getRouteBalance,
	getRouteHelp,
	getRouteFinancialAnalytics,
	getRouteProfile,
	getRouteReleasesList,
	getRouteStatistics,
	getRouteMarket,
	getRoutePromotion,
} from '../../consts/router'
import { ReactComponent as UserProfileSVG } from '@/shared/assets/icons/user-profile.svg'
import { ReactComponent as MyReleasesSVG } from '@/shared/assets/icons/my-releases.svg'
import { ReactComponent as DashboardSVG } from '@/shared/assets/icons/dashboard.svg'
import { ReactComponent as BalanceSVG } from '@/shared/assets/icons/card.svg'
import { ReactComponent as CertificationSVG } from '@/shared/assets/icons/certification.svg'
import { ReactComponent as RubleSVG } from '@/shared/assets/icons/ruble.svg'
import { ReactComponent as FaqSVG } from '@/shared/assets/icons/help.svg'
import { ReactComponent as MarketSVG } from '@/shared/assets/icons/market.svg'
import { ReactComponent as PromotionSVG } from '@/shared/assets/icons/promotion.svg'
import { MenuItemType } from '@/widgets/Menu/model/types/menu'

export const useMainMenuItems = () => {
	const { t } = useTranslation()
	const certUrl = process.env.REACT_APP_CERTIFICATE_URL
	const mainMenuItemsList: MenuItemType[] = [
		{
			text: t('Профиль'),
			path: getRouteProfile(),
			Icon: UserProfileSVG,
		},
		{
			text: t('Мои релизы'),
			path: getRouteReleasesList(),
			Icon: MyReleasesSVG,
		},
		{
			text: t('Статистика'),
			path: getRouteStatistics(),
			Icon: DashboardSVG,
		},
		{
			text: t('Баланс'),
			path: getRouteBalance(),
			Icon: BalanceSVG,
		},
		{
			text: t('Фин. аналитика'),
			path: getRouteFinancialAnalytics(),
			Icon: RubleSVG,
		},
		{
			text: t('Сертификация'),
			path: certUrl as string,
			Icon: CertificationSVG,
			inside: true,
		},
		{
			text: 'Маркет',
			path: getRouteMarket(),
			Icon: MarketSVG,
		},
		{
			text: 'Продвижение',
			path: getRoutePromotion(),
			Icon: PromotionSVG,
		},
	]

	return mainMenuItemsList
}
export const useSecondMenuItems = () => {
	const { t } = useTranslation()
	const secondaryMenuItemsList: MenuItemType[] = [
		{
			text: t('Помощь'),
			path: getRouteHelp(),
			Icon: FaqSVG,
		},
	]
	return secondaryMenuItemsList
}
