import { createSlice } from '@reduxjs/toolkit'
import { PaymentSubscriptionStateScheme } from '../types/paymentSubscriptionSchema'

const initialState: PaymentSubscriptionStateScheme = {
	openedModal: false,
	durationSubscr: '1',
	idSubscr: null,
}

export const paymentSubscriptionSlice = createSlice({
	name: 'paymentSubscription',
	initialState,
	reducers: {
		openModal: (state) => {
			state.openedModal = true
		},
		closeModal: (state) => {
			state.openedModal = false
		},
		setDuration: (state, action) => {
			state.durationSubscr = action.payload
		},
		setIdSubscr: (state, action) => {
			state.idSubscr = action.payload
		},
	},
	extraReducers: () => {},
})

export const { actions: paymentSubscriptionActions } = paymentSubscriptionSlice
export const { reducer: paymentSubscriptionReducer } = paymentSubscriptionSlice
