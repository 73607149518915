import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	globalAlertModal: {
		display: 'flex',
	},
	icon: {
		marginRight: 12,
	},
	content: {},
	title: {
		fontSize: theme.fontSizes.xl,
		[theme.fn.smallerThan('xs')]: {
			fontSize: theme.fontSizes.md,
		},
	},
	text: {
		textAlign: 'justify',
		fontSize: theme.fontSizes.md,
		[theme.fn.smallerThan('xs')]: {
			fontSize: theme.fontSizes.sm,
		},
	},
}))
