import { UserResponse, UserTokenResponse } from '../model/types/userSchema'
import { authApi } from '@/shared/api/authApi'

export const userAuthApi = authApi.injectEndpoints({
	endpoints: (build) => ({
		initAuth: build.query<UserTokenResponse, void>({
			query: () => ({
				url: '/get-token',
			}),
		}),
		getUserData: build.query<UserResponse, void>({
			query: () => ({
				url: '/get-data',
			}),
			providesTags: () => [{ type: 'userData', id: 'all' }],
		}),
		logoutUser: build.mutation<void, void>({
			query: () => ({
				url: '/logout',
				method: 'GET',
			}),
		}),
	}),
})

export const useInitAuth = userAuthApi.useInitAuthQuery
export const useGetUserData = userAuthApi.useGetUserDataQuery
export const useLogoutUser = userAuthApi.useLogoutUserMutation
