import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	text: {
		'& a': {
			color: theme.colors.primary,
			textDecoration: 'underline',
		},
	},
}))
