import { rtkApi } from 'shared/api/rtkApi'
import { WithdrawalMoneyRequest } from '../model/types/withdrawalMoney'

const withdrawalMoneyApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		withdrawalMoney: build.mutation<WithdrawalMoneyRequest, any>({
			query: (body) => ({
				url: '/balance/request-for-pay',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'balance', id: 'all' }],
		}),
	}),
})

export const useWithdrawalMoney = withdrawalMoneyApi.useWithdrawalMoneyMutation
