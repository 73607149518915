import { FC } from 'react'
import { Modal, Alert, Text, Button } from '@mantine/core'
import {
	IconAlertSquareRoundedFilled,
	IconInfoSquareRoundedFilled,
	IconSquareCheckFilled,
	IconCircleArrowRight,
} from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAlertDetails } from '../model/selectors/getAlertDetails'
import { useGlobalAlertModal } from '../hooks/useGlobalAlertModal'
import { useStyles } from './GlobalAlertModal.styles'

export const GlobalAlertModal: FC = () => {
	const { classes } = useStyles()

	const { t } = useTranslation()

	const alertDetails = useSelector(getAlertDetails)
	const { closeGlobalAlert } = useGlobalAlertModal()

	const icons = {
		error: <IconAlertSquareRoundedFilled />,
		success: <IconSquareCheckFilled />,
		info: <IconInfoSquareRoundedFilled />,
	}

	const colors = {
		error: 'red',
		success: 'green',
		info: 'blue',
	}

	const goToLink = (href: string) => {
		closeGlobalAlert()
		window.location.href = href
	}

	return (
		<Modal
			opened={!!alertDetails.message}
			onClose={closeGlobalAlert}
			padding={0}
			withCloseButton={false}
			centered
			transitionProps={{ transition: 'rotate-left' }}
			closeOnClickOutside={false}
			size={'md'}
			zIndex={9999999}
		>
			<Alert color={colors[alertDetails.type]} p={30} withCloseButton={false} onClose={closeGlobalAlert}>
				<div className={classes.globalAlertModal}>
					<div className={classes.icon}>
						<Text fz={30} color={`${colors[alertDetails.type]}.8`}>
							{icons[alertDetails.type]}
						</Text>
					</div>
					<div className={classes.content}>
						<Text className={classes.title} pb={10} fw={900} color={`${colors[alertDetails.type]}.8`}>
							{alertDetails.title}
						</Text>
						<Text className={classes.text}>{alertDetails.message}</Text>
					</div>
				</div>
				{alertDetails.link && (
					<Button
						fullWidth
						onClick={alertDetails.link ? () => goToLink(alertDetails.link?.href ?? '') : undefined}
						mt={20}
						color="dark"
						variant="outline"
						rightIcon={<IconCircleArrowRight stroke={1.2} />}
					>
						{alertDetails.link.text}
					</Button>
				)}
				<Button onClick={closeGlobalAlert} fullWidth mt={30} color={`${colors[alertDetails.type]}.8`}>
					{t('Закрыть')}
				</Button>
			</Alert>
		</Modal>
	)
}
